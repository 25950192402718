<template>
  <div class="login-page">
    <div class="blank-node"></div>
    <div class="l-box">
      <div class="logo">
        <img src="../../../../public/imgs/logo.png" alt="" />
      </div>
      <p class="plarfrom-name">畜禽粪污资源利用化平台</p>
      <Form ref="form" :model="form" :rules="rules" :label-width="0">
        <FormItem prop="userName">
          <Input
            v-model="form.userName"
            prefix="md-person"
            placeholder="请输入用户名"
          ></Input>
        </FormItem>
        <FormItem prop="passWord">
          <Input
            v-model="form.passWord"
            prefix="md-lock"
            password
            type="password"
            placeholder="请输入密码"
          ></Input>
        </FormItem>
      </Form>
      <p class="btn">
        <Button type="primary" :loading="loading" @click="submit">登录</Button>
      </p>
      <p class="register">
        <span>注册</span>
        <span>找回密码</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      form: {
        userName: "",
        passWord: "",
        accountType: "1",
      },
      rules: {
        userName: [{ required: true, message: " " }],
        passWord: [{ required: true, message: " " }],
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.permission.menuList,
    }),
  },
  methods: {
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.loading = true;
        this.$post(this.$api.USER.LOGIN, {
          ...this.form,
        })
          .then((response) => {
            if (response.code !== 200) {
              this.$Message.warning("登陆失败,请确认账号密码或联系管理员");
              return;
            }
            let resp = response.data;
            localStorage.setItem("userId", resp.id);
            localStorage.setItem("userName", resp.name);
            localStorage.setItem("token", resp.token);
            localStorage.setItem("companyNo", resp.user.companyNo);
            this.$store.dispatch("initApp").then(() => {
              this.$nextTick(() => {
                // let routeName = this.getNextRoute();
                this.$router.push({ name: "home" });
              });
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    getNextRoute(menus) {
      if (!menus) menus = this.menuList;
      for (let i = 0; i < menus.length; i++) {
        let menu = menus[i];
        if (menu.url) return menu.url;
        if (menu.children) {
          let subMenu = this.getNextRoute(menu.children);
          if (subMenu) return subMenu;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>